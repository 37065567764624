<template>
  <div
    class="relative w-auto h-64 max-w-xs mb-8 overflow-hidden text-center transition-all rounded-md shadow-md bg-neutral-lighter group hover:bg-primary-mid active:bg-primary"
  >
    <div class="relative">
      <img
        :src="require(`@/assets/${filename}`)"
        :alt="filename"
        class="absolute min-w-full min-h-full transition-opacity opacity-100 group-hover:opacity-0"
      />
      <p
        class="relative p-6 text-base text-white transition-opacity opacity-0 group-hover:opacity-100"
      >
        <slot />
      </p>
    </div>
    <p
      class="absolute bottom-0 w-full px-4 py-4 text-2xl font-semibold transition-colors bg-neutral-lighter group-hover:bg-transparent group-hover:text-white"
    >
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    filename: String
  }
};
</script>
