<template>
  <base-view id="services">
    <view-section v-if="!planSelected">
      <h1 class="mb-8 text-4xl font-semibold">Our Services</h1>
      <div
        class="flex flex-col items-stretch px-0 py-8 md:flex-row md:flex-wrap md:justify-evenly"
      >
        <router-link to="/services/cafeteria">
          <PlanCard title="Cafeteria Plan" filename="cafeteria.jpg">
            <p>
              Employer offered program to help employees pay for certain
              expenses (like health insurance and dependent care) with pre-tax
              dollars.
            </p>
          </PlanCard>
        </router-link>

        <router-link to="/services/fsa">
          <PlanCard title="Flexible Spending Account" filename="fsa.jpg">
            <p>
              A front loaded, employee benefit account which can be used to pay
              copays, out-of-pocket medical treatments or supplies, and
              dependent healthcare expenses.
            </p>
          </PlanCard>
        </router-link>

        <router-link to="/services/dca">
          <PlanCard title="Dependent Care Assistance" filename="dca.jpg">
            <p>
              A pre-tax benefit account used to pay for dependent care services,
              such as preschool, summer day camp, before or after school
              programs, and daycare.
            </p>
          </PlanCard>
        </router-link>

        <router-link to="/services/hsa">
          <PlanCard title="Health Savings Account" filename="hsa.jpg">
            <p>
              A tax-advantaged medical savings account available to employees
              who are enrolled in a high-deductible health plan (HDHP).
            </p>
          </PlanCard>
        </router-link>

        <router-link to="/services/hra">
          <PlanCard title="Health Reimbursement Arrangement" filename="hra.jpg">
            <p>
              A benefit arrangement used to reimburse employees for
              out-of-pocket medical expenses and personal health insurance
              premiums.
            </p>
          </PlanCard>
        </router-link>

        <router-link to="/services/transit">
          <PlanCard
            title="Qualified Transit/Parking Account"
            filename="transit.jpg"
          >
            <p>
              A benefit program allowing employees to reduce their commuting
              expenses for transit, vanpooling, and work-related parking costs.
            </p>
          </PlanCard>
        </router-link>

        <router-link to="/services/wellness">
          <PlanCard title="Wellness Accounts" filename="wellness.jpg">
            <p>
              A personalized health program which provides employees with a
              taxable allowance to spend on wellness-related activities and
              products.
            </p>
          </PlanCard>
        </router-link>

        <router-link to="/services/lsa">
          <PlanCard
            title="Lifestyle Spending Accounts"
            filename="lifestyle.jpg"
          >
            <p>
              A personalized employer offered program that allow after-tax funds
              for employees' everyday needs.
            </p>
          </PlanCard>
        </router-link>

        <router-link to="/services/tuition">
          <PlanCard title="Tuition Reimbursement" filename="tuition.jpg">
            <p>
              A pre-tax benefit program allowing employees to be reimbursed for
              education related expenses such as tuition.
            </p>
          </PlanCard>
        </router-link>

        <router-link to="/services/cobra">
          <PlanCard title="COBRA Administration" filename="cobra.jpg">
            <p>
              A health insurance program allowing employees to continue previous
              health coverage in the case of lost employment or reduction of
              hours.
            </p>
          </PlanCard>
        </router-link>
        <!-- <router-link to="/ichra">
          <PlanCard title="Small Employer HRA" filename="ichra.jpg">
            <p>
              A way for employers of all shapes and sizes to reimburse their
              employees for medical expenses and household health care costs.
            </p>
          </PlanCard>
        </router-link> -->
      </div>
    </view-section>

    <view-section v-else>
      <div class="flex flex-col md:flex-row md:order-1">
        <router-view
          class="w-full h-full mx-auto align-top md:px-4 lg:px-0 max-w-prose md:order-1"
        />
        <div class="p-0 m-0 sidebar min-w-72 md:border-r">
          <ServicesNav v-if="screenWidth > 768" />
          <slot name="extras" />
          <router-view
            class="flex flex-col items-stretch w-64 m-0 mx-auto text-center md:mx-0 md:text-left"
            name="extras"
          />
        </div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

import ServicesNav from "./components/ServicesNav.vue";
import PlanCard from "./components/PlanCard.vue";

export default {
  components: {
    BaseView,
    ViewSection,
    ServicesNav: ServicesNav,
    PlanCard: PlanCard
  },
  data() {
    return {
      links: [
        "Cafeteria Plan",
        "Flexible Spending Account",
        "Dependent Care Assistance Program",
        "Health Savings Account",
        "Health Reimbursement Arrangement",
        "Qualified Transit/Parking Account",
        "Wellness Accounts",
        "Lifestyle Spending Account",
        "Tuition Reimbursement",
        "COBRA Administration"
      ],
      screenWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  computed: {
    planSelected: function() {
      if (this.$route.path === "/services") {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.min-w-72 {
  min-width: 18rem;
}
</style>
